import React, { useState } from 'react'
// motion
import { motion } from 'framer-motion'
// variants
import { fadeIn } from '../features/variants'
// icons
import { MdOutlineZoomOutMap, MdOutlineZoomInMap, MdInsertLink } from 'react-icons/md'
import { FaGithub } from 'react-icons/fa'
// img
import Img1 from '../assets/myWork/PDS1.jpg'
import Img2 from '../assets/myWork/PDS2.jpg'
import Img3 from '../assets/myWork/PDS3.jpg'
import Img4 from '../assets/myWork/PDS4.jpg'
import Img5 from '../assets/myWork/BKS1.png'
import Img6 from '../assets/myWork/BKS2.png'
import Img7 from '../assets/myWork/BKS3.png'
import Img8 from '../assets/myWork/pdsReact.jpg'
import Img9 from '../assets/myWork/pdsReact2.jpg'
import Img10 from '../assets/myWork/pdsReact3.jpg'
import Img11 from '../assets/myWork/pdsReact4.jpg'
import Img12 from '../assets/myWork/pdsReact5.jpg'
import Img13 from '../assets/myWork/pdsReact6.jpg'
import Img14 from '../assets/myWork/pdsReact7.jpg'
import Video1 from '../assets/myWork/videoPds.mp4'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react'
// Import Swiper styles
import 'swiper/css'
import 'swiper/css/navigation'
// import required modules
import { Navigation } from 'swiper/modules'
//translation
import { useTranslation } from 'react-i18next'

const Work = () => {
  const { t } = useTranslation()
  const [isZoomed, setIsZoomed] = useState(false)
  const [isZoomed2, setIsZoomed2] = useState(false)

  const handleZoomToggle = () => {
    setIsZoomed((prevIsZoomed) => !prevIsZoomed)
    const body = document.body
    const html = document.documentElement
    if (isZoomed) {
      body.style.overflow = 'auto'
      html.style.overflow = 'auto'
    } else {
      body.style.overflow = 'hidden'
      html.style.overflow = 'hidden'
    }
  }

  const handleZoomToggle2 = () => {
    setIsZoomed2((prevIsZoomed) => !prevIsZoomed)
    const body = document.body
    const html = document.documentElement
    if (isZoomed2) {
      body.style.overflow = 'auto'
      html.style.overflow = 'auto'
    } else {
      body.style.overflow = 'hidden'
      html.style.overflow = 'hidden'
    }
  }

  return (
    <section id='work' className='section h-auto py-16'>
      <div className='container mx-auto'>
        {/* Main text  */}
        <motion.div
          variants={fadeIn('right', 0.3)}
          initial='hidden'
          whileInView={'show'}
          viewport={{ once: true, amount: 0.3 }}
          className='flex-col md:w-[80%] text-left mb-24'
        >
          <h2 className='h2 text-collor4 mb-6 leading-tight'>{t('description.work.text1')}</h2>
          <h3 className='h3 mx-w-[455px]   md:bg-collor3/60 backdrop-blur-3xl bg-collor1/60 px-1 border-b-2 border-white/20'>
            {t('description.work.text2')}
          </h3>
        </motion.div>
        {/*  */}
        {/* Projects  */}
        {/*  */}
        <motion.div
          variants={fadeIn('left', 0.3)}
          initial='hidden'
          whileInView={'show'}
          viewport={{ once: true, amount: 0.1 }}
          className='flex flex-col gap-y-20'
        >
          {/*  */}
          {/* 1 card */}
          {/*  */}
          <div className='flex lg:flex-row flex-col lg:items-center mb-10 border-b-2 border-white/20 pb-1 '>
            {/* overlay  */}
            <div className={`${isZoomed ? 'bg-collor1 w-full h-full fixed z-40 top-0 left-0' : ''}`}></div>
            {/* image  zoom*/}
            <div
              className={`  ${
                isZoomed
                  ? 'fixed bg-black z-50 md:p-20 flex items-center inset-0 justify-center'
                  : 'relative border-2 border-white/10 rounded-xl  overflow-hidden shadow-2xl shadow-black'
              }`}
            >
              {/* image  */}
              <Swiper rewind={true} navigation={true} modules={[Navigation]} className={'mySwiper'}>
                <SwiperSlide>
                  <img src={Img1} alt='presenting my sollution for machine tracking' className='mx-auto' />
                </SwiperSlide>
                <SwiperSlide>
                  {' '}
                  <img src={Img2} alt='presenting my sollution for machine tracking' className='mx-auto' />
                </SwiperSlide>
                <SwiperSlide>
                  {' '}
                  <img src={Img3} alt='presenting my sollution for machine tracking' className='mx-auto' />
                </SwiperSlide>
                <SwiperSlide>
                  {' '}
                  <img src={Img4} alt='presenting my sollution for machine tracking' className='mx-auto' />
                </SwiperSlide>
                {/* technology */}
                <div className={`${isZoomed ? ' hidden' : ' text-center bg-collor1 p-1 rounded-md text-sm md:text-xl '}`}>
                  <span className='text-gradient'>ASP.NET | MVC | VANILLA JS | RAZOR | AXIOS | S7.NET | JQUERY</span>
                </div>
              </Swiper>

              {/* Zoom */}
              <div
                className='absolute top-4 right-4 z-40 bg-collor3 p-1 cursor-pointer hover:scale-125'
                onClick={handleZoomToggle}
              >
                {isZoomed ? <MdOutlineZoomInMap size={34} color='#fff' /> : <MdOutlineZoomOutMap size={34} color='#fff' />}
              </div>
            </div>
            {/* text  */}
            <div className='lg:w-4/6 lg:ml-10 '>
              <h2 className='h2 leading-tight text-collor4 mt-4'>
                Process data <br />
                Share.
              </h2>
              <p className='max-w-sm mb-4'>{t('description.work.text3')}</p>

              <a
                href='https://github.com/tomix421q/processDataShare'
                className='flex items-center justify-center gap-x-4 btn btn-lg my-6 '
                target='_blank'
                rel='noreferrer'
              >
                <FaGithub size={35} /> CODE LINK
              </a>
            </div>
          </div>

          {/*  */}
          {/* 2 card */}
          {/*  */}
          <div className='flex lg:flex-row flex-col lg:items-center mb-10 border-b-2 border-white/20 pb-1 '>
            {/* overlay  */}
            <div className={`${isZoomed2 ? 'bg-black w-full h-full fixed z-40 top-0 left-0' : ''}`}></div>
            {/* image  zoom*/}
            <div
              className={`  ${
                isZoomed2
                  ? 'fixed bg-collor1 z-50 md:p-20 flex items-center inset-0 justify-center'
                  : 'relative border-2 border-white/10 rounded-xl  overflow-hidden shadow-2xl shadow-black'
              }`}
            >
              {/* image  */}
              <Swiper rewind={true} navigation={true} modules={[Navigation]} className={'mySwiper'}>
                <SwiperSlide>
                  <img src={Img8} alt='presenting my sollution for machine tracking' className='mx-auto' />
                </SwiperSlide>
                <SwiperSlide>
                  {' '}
                  <img src={Img9} alt='presenting my sollution for machine tracking' className='mx-auto' />
                </SwiperSlide>
                <SwiperSlide>
                  {' '}
                  <img src={Img10} alt='presenting my sollution for machine tracking' className='mx-auto' />
                </SwiperSlide>
                <SwiperSlide>
                  {' '}
                  <img src={Img11} alt='presenting my sollution for machine tracking' className='mx-auto' />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={Img12} alt='presenting my sollution for machine tracking' className='mx-auto' />
                </SwiperSlide>
                <SwiperSlide>
                  {' '}
                  <img src={Img13} alt='presenting my sollution for machine tracking' className='mx-auto' />
                </SwiperSlide>
                <SwiperSlide>
                  {' '}
                  <img src={Img14} alt='presenting my sollution for machine tracking' className='mx-auto' />
                </SwiperSlide>
                <SwiperSlide>
                  {' '}
                  <video className='mx-auto ' controls>
                    <source src={Video1} type='video/mp4'></source>
                  </video>
                </SwiperSlide>

                {/* technology */}
                <div className={`${isZoomed ? ' hidden' : ' text-center bg-collor1 p-1 rounded-md text-sm md:text-xl '}`}>
                  <span className='text-gradient'>.NET API | REACT | TAILWIND | ENTITY FM | SQL | AXIOS | S7.NET </span>
                </div>
              </Swiper>

              {/* Zoom */}
              <div
                className='absolute top-4 right-4 z-40 bg-collor3 p-1 cursor-pointer hover:scale-125'
                onClick={handleZoomToggle2}
              >
                {isZoomed2 ? <MdOutlineZoomInMap size={34} color='#fff' /> : <MdOutlineZoomOutMap size={34} color='#fff' />}
              </div>
            </div>
            {/* text  */}
            <div className='lg:w-4/6 lg:ml-10 '>
              <h2 className='h2 leading-tight text-collor4 mt-4'>
                Process data <br />
                Share.[Update]
              </h2>
              <p className='max-w-sm mb-4'>{t('description.work.text7')}</p>

              <a
                href='https://github.com/tomix421q/ProcessDataShare_dotnetReact'
                className='flex items-center justify-center gap-x-4 btn btn-lg my-6 '
                target='_blank'
                rel='noreferrer'
              >
                <FaGithub size={35} /> CODE LINK
              </a>
            </div>
          </div>

          {/*  */}
          {/* 3 card */}
          {/*  */}
          <div className='flex lg:flex-row flex-col lg:items-center mb-10 border-b-2 border-white/20 pb-10 '>
            {/* overlay  */}
            <div className={`${isZoomed2 ? 'bg-collor1 w-full h-full fixed z-40 top-0 left-0' : ''}`}></div>
            {/* image  zoom*/}
            <div className={'relative rounded-xl border border-white/10 overflow-hidden shadow-2xl shadow-black'}>
              {/* image  */}
              <Swiper rewind={true} navigation={true} modules={[Navigation]} className={'mySwiper'}>
                <SwiperSlide>
                  <img src={Img5} alt='presenting web design' />
                </SwiperSlide>
                <SwiperSlide>
                  {' '}
                  <img src={Img6} alt='presenting web design' />
                </SwiperSlide>
                <SwiperSlide className='bg-collor1'>
                  {' '}
                  <img src={Img7} alt='presenting web design' />
                </SwiperSlide>
              </Swiper>
              {/* Technology */}
              <div className='text-center bg-collor1 p-1 rounded-md text-sm md:text-xl'>
                <span className='text-gradient'>REACT | VITE | TAILWIND | JAVASCRIPT</span>
              </div>
            </div>
            {/* text  */}
            <div className='lg:w-4/6 lg:ml-10 '>
              <h2 className='h2 leading-tight text-collor4 mt-4'>
                BKS <br />
                WEB.
              </h2>
              <p className='max-w-sm mb-4'>{t('description.work.text4')}</p>{' '}
              <a
                href='https://github.com/tomix421q/bks-web'
                className='flex items-center justify-center btn btn-lg my-6 gap-x-4 text-center '
                target='_blank'
                rel='noreferrer'
              >
                <FaGithub size={35} /> CODE LINK
              </a>{' '}
              <a
                href='https://bks-obkladacskeprace.sk/'
                className='flex items-center justify-center gap-x-4 btn btn-lg logo-gradient text-center'
                target='_blank'
                rel='noreferrer'
              >
                <MdInsertLink size={45} /> LINK TO WEB
              </a>
            </div>
          </div>
        </motion.div>
        {/*  */}
        {/* END  */}
        {/*  */}
        <motion.div
          variants={fadeIn('right', 0.2)}
          initial='hidden'
          whileInView={'show'}
          viewport={{ once: true, amount: 0.3 }}
          wrapper='div'
          className='flex justify-between gap-x-16 items-center md:my-20'
        >
          <h2 className='h2 leading-tight text-collor4'>{t('description.work.text5')}</h2>
          <motion.a
            animate={{ scale: [1, 1.1, 1] }}
            transition={{ ease: 'easeInOut', duration: 2, repeat: Infinity }}
            whileHover={{ scale: 1.3 }}
            style={{ cursor: 'pointer' }}
            href='https://github.com/tomix421q'
            target='_blank'
            rel='noreferrer'
            title='Github'
            className=' shadow-xl shadow-black'
          >
            <FaGithub size={85} />
          </motion.a>
        </motion.div>
        <motion.div
          variants={fadeIn('left', 0.5)}
          initial='hidden'
          whileInView={'show'}
          viewport={{ once: true, amount: 0.3 }}
          wrapper='div'
          className='flex flex-col md:flex-row justify-between items-center  gap-x-28 border-b-2 border-white/20 md:py-16'
        >
          <p className='text-sm md:text-md uppercase flex text-white/60 tracking-wider'>{t('description.work.text6')}</p>

          <a
            className='btn btn-lg md:w-[50%] w-full  text-white hover:scale-105 mx-auto shadow-lg shadow-black my-6 flex items-center justify-center text-center'
            href='#contact'
          >
            {t('description.buttons.wrkWithMe')}
          </a>
        </motion.div>
      </div>
    </section>
  )
}

export default Work
