import React from 'react'
//countup
import CountUp from 'react-countup'
// intersection observer hook
import { useInView } from 'react-intersection-observer'
// motion
import { motion } from 'framer-motion'
//variant
import { fadeIn } from '../features/variants'
//translation
import { useTranslation } from 'react-i18next'

const About = () => {
  const { t } = useTranslation()
  const [ref, inView] = useInView({
    threshold: 0.4,
  })

  return (
    <section id='about' className='min-h-screen section my-24' ref={ref}>
      <div className='container mx-auto'>
        <div className='flex flex-col  lg:flex-row lg:items-center  lg:gap-y-20'>
          {/* img  */}
          <motion.div
            variants={fadeIn('right', 0.3)}
            initial='hidden'
            whileInView={'show'}
            viewport={{ once: true, amount: 0.3 }}
            className='hidden md:flex flex-1 bg-about bg-contain bg-no-repeat h-[300px]  md:h-[640px] mix-blend-lighten  bg-top'
          ></motion.div>
          {/* text  */}
          <motion.div
            variants={fadeIn('right', 0.3)}
            initial='hidden'
            whileInView={'show'}
            viewport={{ once: true, amount: 0.3 }}
            className='flex-1'
          >
            <h2 className='text-collor4 h2 pt-2'>{t('description.buttons.about')}</h2>
            <p className='md:mb-8 lg:text-left text-base md:text-lg'>
              {t('description.about.text2')} <span className='text-collor2 text-xl'>6</span>
              {t('description.about.text3')} <span className='text-collor2 text-xl'>4</span> {t('description.about.text4')}
              <span className='text-collor2 text-xl'>{t('description.about.text5')}</span>
              {t('description.about.text6')}
              <span className='text-collor2 text-xl'>{t('description.about.text7')}</span>
              {t('description.about.text8')}
            </p>

            {/* stats */}
            <div className=' flex flex-col md:flex-row gap-x-6 gap-y-6 lg:gap-x-10  my-10 justify-center'>
              <div className='flex gap-x-2 items-center md:block'>
                <div className='text-[40px] font-tertiary text-gradient mb-2'>
                  {inView ? <CountUp start={0} end={6} duration={4} /> : null}
                </div>
                <div className='font-primary text-sm tracking-[2px]'>{t('description.about.stats1')}</div>
              </div>
              <div className='flex gap-x-2 items-center md:block'>
                <div className='text-[40px] font-tertiary text-gradient mb-2'>
                  {inView ? <CountUp start={0} end={4} duration={4} /> : null}
                </div>
                <div className='font-primary text-sm tracking-[2px]'>{t('description.about.stats2')}</div>
              </div>
              <span className='w-2 hidden lg:block h-auto btn  rounded-full'></span>
              <div className='flex gap-x-2 items-center md:block'>
                <div className='text-[40px] font-tertiary text-gradient mb-2'>
                  {inView ? <CountUp start={0} end={3} duration={4} /> : null}
                </div>
                <div className='font-primary text-sm tracking-[2px]'>{t('description.about.stats3')}</div>
              </div>
              <div className='flex gap-x-2 items-center md:block'>
                <div className='text-[40px] font-tertiary text-gradient mb-2'>
                  {inView ? <CountUp start={0} end={6} duration={4} /> : null}
                </div>
                <div className='font-primary text-sm tracking-[2px]'> {t('description.about.stats4')}</div>
              </div>
              {/* end stats  */}
            </div>
            {/*  */}
            {/* mobile avatar  */}
            {/*  */}
            <motion.div
              initial={{ opacity: 0 }} // Začiatočná hodnota opacity
              animate={{ opacity: 1 }} // Koncová hodnota opacity
              transition={{ duration: 3 }} // Trvanie animácie v sekundách
              viewport={{ once: true, amount: 0.6 }}
              className='bg-about bg-contain bg-no-repeat h-[380px] md:hidden bg-center mix-blend-lighten'
            ></motion.div>
            <div className='flex gap-x-8 items-center justify-center mt-20'>
              <a className='btn btn-lg flex items-center justify-center' href='#contact'>
                {t('description.buttons.contact')}
              </a>
              <a href='#work' className='text-gradient btn-link'>
                {t('description.buttons.portfolio')}
              </a>
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  )
}

export default About
