import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
// import LanguageDetector from 'i18next-browser-languagedetector'

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  // .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          description: {
            banner: {
              text1: 'Hello,',
              text2: " I'm Tomas",
              text3: 'Welcome to my portfolio web',
              text4:
                ' My passion is in automation and programming, and therefore I constantly strive to improve and grow. I am following current trends in technology and am always ready for new challenges and projects.',
              text5: 'PLC programing\nRobot programing\nHMI visualization\nOPC & OPCUA \nWeb development',
            },
            about: {
              text1: 'About me',
              text2: 'My proficiency includes ',
              text3:
                ' years in software engineering and automation, with a dedicated focus on automation within the automotive sector for the past',
              text4: 'years, specializing primarly with a focus on',
              text5: ' PLC and robot',
              text6: ' programming. Alongside my primary role, I actively contribute to web development using',
              text7: ' React and .NET.',
              text8:
                ' My objective is to merge technical proficiency with a creative approach to automation and ongoing web development. Concurrently, I am consistently striving to expand my knowledge and learn new skills.',
              stats1: 'Years of experience in automotive',
              stats2: 'Years of experience with PLC programming',
              stats3: 'Years of experience with software programming',
              stats4: 'Own web projects',
            },
            services: {
              text1:
                'My knowledge about siemens PLC consists of LAD, FBD, SCL. I also have experience with PID, which concerns editing and debugging. I have experience with various aspects of small-scale implementations, including recorders, controls, logic editing, communication between two PLCs, and more. While I have had successful small-scale implementations, I am looking to move on to larger projects in the future.',
              text2:
                'Regarding ABB robots, I have expertise in reactivating robots for projects, including safety measures, axis tuning, positions, and commands. I am proficient in using RobotStudio and the Rapid programming language. Additionally, I am familiar with tuning and implementations of UR robots.',
              text3:
                'I am involved in numerous camera inspections, focusing on implementing and fine-tuning controls. The brands I collaborate with are Keyence and Cognex, utilizing their respective software, Cognex Designer for Cognex, and CV-X software for Keyence.',
              text4:
                'I have been actively learning JavaScript for over 2 years, with a primary focus on React. In addition to React, I have experience in working with vanilla JavaScript and jQuery. I am also proficient in using CSS frameworks, including Tailwind, Bootstrap, and Semantic UI.',
              text5:
                'Recently, I have been actively learning C# with a specific emphasis on .NET backend and Entity Framework. I have also gained experience working on MVC complete solutions with the Razor frontend.​',
              text6:
                'I have limited experience with databases, working mostly with MongoDB and SQL databases, primarily through Entity Framework and Firebase.',
              text7: 'SKILLS AND SPECIALIZATIONS​',
              text8: 'My experience with software engineering and automation',
            },
            work: {
              text1: 'MY LATEST WORK',
              text2:
                'Working on diferent projects provides me with new opportunities to learn and acquire new skills in the future.',
              text3:
                'This project presents a solution that is implemented within the company for real-time machine monitoring. In the future, I intend to apply the aforementioned project to react + .net with database application.',
              text4:
                'I have developed this website with the purpose of presenting the services of the BKS company with an emphasis on simplicity and design.',
              text5: 'Visit my github for more projects',
              text6:
                "Due to confidentiality agreements, I can't share specific details about PLC projects publicly. However, I'm available to discuss my automation expertise privately. Please feel free to reach out, and I'll be happy to provide more insights into my work.",
              text7:
                'I developed this project as a continuation of the previous one, where I added a database, used React, and introduced several more extensions.Additionally, this project is designed for real-time monitoring and data storage within our company’s network, making it inaccessible for online viewing.',
            },
            contact: {
              text1: 'Get in touch',
              text2: 'Lets work together!',
              text3: 'Your name',
              text4: 'Your email',
              text5: 'Your message​',
              text6: 'Thank you, I will contact you as soon as possible',
            },

            buttons: {
              header1: 'Automation',
              header2: ' Software enginering',
              wrkWithMe: 'Contact me',
              about: 'About me',
              portfolio: 'Portfolio',
              contact: 'Contact',
              work: 'My work',
              sendMsg: 'Send message',
            },
          },
        },
      },
      sk: {
        translation: {
          description: {
            banner: {
              text1: 'Ahoj, ',
              text2: 'som Tomáš',
              text3: 'Vítam ťa na mojom webe',
              text4:
                ' Mojou vášňou je automatizácia a programovanie, pričom sa neustále snažím zdokonaľovať a rásť. Sledujem súčasné trendy v technológiách a vždy som pripravený na nové výzvy a projekty.',
              text5: 'PLC programovanie\nProgramovanie robotov\nVizualizácia HMI\nOPC & OPCUA\nTvorba webov',
            },
            about: {
              text1: 'O mne',
              text2: 'Mám ',
              text3: ' rokov praxe v automatizácii (automobilový priemysel) a',
              text4: ' roky špecializácie predovšetkým so zameraním na',
              text5: ' programovanie PLC a robotov.',
              text6:
                ' Okrem svojej hlavnej úlohy sa snažím programovať weby a implementovať rôzne softvérové vylepšenia. V oblasti programovania a implementácie softvérových vylepšení zvyknem využívať',
              text7: ' React a C#(.NET) .',
              text8:
                ' Môj zámer je kombinovať technickú zručnosť s inovatívnym prístupom k automatizácii a súčasným vývojom webových riešení. Súčasne sa neustále snažím rásť a učiť sa nové veci.',
              stats1: 'Rokov skúseností v automobilovom priemysle',
              stats2: 'Roky skúseností s programovaním PLC',
              stats3: 'Roky skúseností s programovaním softvéru',
              stats4: 'Vlastných webových projektov',
            },
            services: {
              text1:
                'Mám skúseností s LAD, FBD a snažím sa učiť SCL. Pracoval som s PID, pokiaľ ide o úpravy a ladenie, a tiež som bol pri implementácii OPC. Realizoval som rôzne menšie úpravy malého rozsahu, vrátane nastavovania rekordérov, riadiacich systémov, úprav logiky, komunikácie medzi dvoma PLC a mnoho ďalších. Do budúcnosti by som sa chcel posunúť a pracovať na implementácii väčších projektov.',
              text2:
                'Pokiaľ ide o roboty od spoločnosti ABB, mám skúseností s oživením robotov na rôznych projektoch. Taktiež mám znalosti s nastavovaním bezpečnostných opatrení, ladením osí a nastavovaním pozícií. Viem pracovať aj s Robot Studiom a bez problémov upravujem či pridávam logiku. Stretol som sa aj s UR robotmi pri implementácii a disponujem tiež niektorými zručnosťami, pokiaľ ide o drobné úpravy.',
              text3:
                'Čo sa týka kamerových inšpekcii, mám viacero znalostí s pridávaním a ladením inšpekcii. Pri mojej práci som doteraz využíval riešenia od spoločnosti Keyence a Cognex. Nemám priamu prax s implementáciou, ale viem si poradiť aj s väčšími zásahmi.',
              text4:
                'Aktívne sa učím JavaScript už viac ako 2 roky, pričom sa hlavne sústredím na React. Okrem Reactu mám skúseností s prácou v čistom JavaScripte a jQuery. Taktiež ovládam CSS frameworky, vrátane Tailwind, Bootstrap a Semantic UI.',
              text5:
                'V poslednej dobe som sa aktívne venoval štúdiu jazyka C#, s osobitným dôrazom na .NET backend a Entity Framework. Získal som aj prax pri práci na komplexných riešeniach MVC s Razor frontendom.',
              text6:
                'Mám aj niekoľko obmedzených skúseností s databázami. Tento rok by som sa chcel sústrediť na ich zdokonalenie a ďalší rozvoj. Zatiaľ som pracoval s MongoDB a začínam sa učiť pracovať s SQL databázami, predovšetkým cez Entity Framework. Okrem toho plánujem rozšíriť svoje znalosti o Firebase.',
              text7: 'Schopnosti a odborné znalosti​',
              text8: 'Moje skúseností v oblasti programovania a automatizácie',
            },
            work: {
              text1: 'Moja posledná práca',
              text2:
                'Práca na rôznych projektoch mi otvára nové perspektívy pre rozvoj a zdokonaľovanie nových schopností do budúcnosti.',
              text3:
                'Tento projekt je vypracovaný ako riešenie pre monitorovanie strojov v reálnom čase a je implementovaný vo firemnom prostredí. Mám v pláne tento projekt rozšíriť a implementovať ho do aplikácie vytvorenej s využitím technológií react + .net s integrovanou databázou.',
              text4:
                'Vyššie uvedenú webovú stránku som vytvoril s cieľom prezentovať služby spoločnosti BKS. Dôraz som kládol na jednoduchosť a vizuálne príjemný dizajn.',
              text5: 'Pozrite si môj GitHub pre viac projektov.',
              text6:
                'Z dôvodu dohôd nemôžem verejne zdieľať konkrétne informácie o PLC projektoch. Avšak som k dispozícii na diskusiu v súkromí. Neváhajte ma kontaktovať, a rád poskytnem ďalšie informácie o mojej práci.',
              text7:
                'Tento projekt som vyvinul ako pokračovanie predchádzajúceho, kde som pridal databázu, použil React a zaviedol niekoľko ďalších rozšírení. Okrem toho je tento projekt určený na monitorovanie v reálnom čase a ukladanie dát v rámci siete našej spoločnosti, vďaka čomu je nedostupný pre online prezeranie.',
            },
            contact: {
              text1: 'Napíšte mi',
              text2: 'Poďme spolu pracovať',
              text3: 'Vaše meno',
              text4: 'Váš email',
              text5: 'Vaša správa​',
              text6: 'Ďakujem, budem vás kontaktovať čo najskôr.',
            },

            buttons: {
              header1: 'Automatizácia',
              header2: ' Vývoj softvéru',
              wrkWithMe: 'Kontaktuj ma',
              about: 'O mne',
              portfolio: 'Portfólio',
              contact: 'Kontakt',
              work: 'Moja práca',
              sendMsg: 'Poslať správu',
            },
          },
        },
      },
      //
    },
  })

export default i18n
