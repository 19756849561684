import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import './features/gradients.css'
import App from './App'
// import i18n (needs to be bundled ;))
import './features/translate/i118n'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)
