import { useState } from 'react'
import React from 'react'
// icons
import iconSlovakia from '../../assets/icons/slovakia.png'
import iconEnglish from '../../assets/icons/english.png'
// translate
import { useTranslation } from 'react-i18next'

//
const Translatore = () => {
  const { i18n } = useTranslation()

  const lngs = {
    en: { nativeName: 'EN' },
    sk: { nativeName: 'SK' },
  }
  const [isOpen, setIsOpen] = useState(false)

  const toggleDropdown = () => {
    setIsOpen(!isOpen)
  }

  const handleLanguageChange = (lng) => {
    i18n.changeLanguage(lng)
    setIsOpen(false) // Skryjte dropdown menu po změně jazyka
  }

  return (
    <div className='flex flex-col font-primary2 tracking-widest '>
      <button className='flex items-center uppercase' onClick={toggleDropdown}>
        {/* {i18n.language === 'en' ? (
                <img src={iconEnglish} alt='en' className='mr-1 w-5' />
              ) : (
                <img src={iconSlovakia} alt='sk' className='mr-1 w-5' />
              )} */}
        <h2 className='text-gradient2'> {i18n.language}</h2>
        <svg
          className={`w-4 h-4 ml-1 transition-transform transform  ${isOpen ? 'rotate-180' : ''}`}
          fill='none'
          stroke='currentColor'
          viewBox='0 0 24 24'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M19 9l-7 7-7-7' />
        </svg>
      </button>
      {isOpen && (
        <div className='absolute w-30 bg-collor1 rounded-lg shadow-lg shadow-black mt-8 z-50 font-primary tracking-widest ml-[-10px]'>
          {Object.keys(lngs).map((lng) => (
            <button
              key={lng}
              className={`p-3 text-sm leading-5 focus:outline-none flex ${i18n.language === lng ? 'text-collor4' : 'font-light'}`}
              onClick={() => handleLanguageChange(lng)}
            >
              {lng === 'en' ? (
                <img src={iconEnglish} alt='en' className='mr-1 w-5' />
              ) : (
                <img src={iconSlovakia} alt='sk' className='mr-1 w-5' />
              )}
              {lngs[lng].nativeName}
            </button>
          ))}
        </div>
      )}
    </div>
  )
}
export default Translatore
