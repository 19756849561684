import { Link } from 'react-scroll'

function Footer() {
  return (
    <section className='bg-black/30  min-h-[350px] mt-[200px] '>
      <div className=' container mx-auto flex-col justify-center items-center text-collor4'>
        <Link
          to='home'
          smooth={true}
          spy={true}
          offset={-200}
          className='flex-col flex items-center hover:scale-125 transition-all ease-out px-16  cursor-pointer'
        >
          {/* <IoIosArrowUp size={50} className='text-white' /> */}
          <h2 className='h2 text-2xl tracking-widest text-gradient pt-8'>HOME</h2>
        </Link>
        <div className='text-center text-sm mx-auto  text-white/60 border-b border-collor4/20 mb-4 pb-4'>
          <h4 className='mb-2'>Contact:</h4>
          <p>
            Tel:{' '}
            <a href='tel:+421910387825' title='Call to +421910387825' className='text-white/80'>
              +421910387825
            </a>
          </p>
          <p>
            Mail:{' '}
            <a
              href='mailto:zilka.tomas421@gmail.com'
              title='Send mail to zilka.tomas421@gmail.com'
              target='_blank'
              rel='noreferrer'
              className='text-white/80'
            >
              zilka.tomas421@gmail.com
            </a>
          </p>
        </div>
        <p className='text-base text-center text-white/60 py-2'>© 2024 Tomáš Žilka. All rights reserved.</p>
      </div>
    </section>
  )
}
export default Footer
