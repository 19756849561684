import { React, useState } from 'react'
// motion
import { motion } from 'framer-motion'
// variants
import { fadeIn } from '../features/variants'
// icons
import { CiMail, CiLinkedin, CiMobile3 } from 'react-icons/ci'
import { PiMessengerLogo } from 'react-icons/pi'
//translation
import { useTranslation } from 'react-i18next'

const Contact = () => {
  const { t } = useTranslation()
  const [submitted, setSubmitted] = useState(false)
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')

  const handleSubmit = async (e) => {
    e.preventDefault()

    const form = e.target
    const formData = new FormData(form)

    try {
      const response = await fetch(form.action, {
        method: 'POST',
        body: formData,
        headers: {
          Accept: 'application/json',
        },
      })

      if (response.ok) {
        setSubmitted(true)
        setName('')
        setEmail('')
        setMessage('')
        setTimeout(() => {
          setSubmitted(false)
        }, 3000)
      } else {
        // Ak je odoslanie neúspešné, vykonajte potrebnú akciu (napr. zobrazenie chybovej správy)
      }
    } catch (error) {
      console.error('Error:', error)
      // Zobrazenie chybovej správy
    }
  }

  return (
    <section id='contact' className='lg:section h-screen py-16'>
      <div className='container mx-auto'>
        <div className='flex flex-col lg:flex-row '>
          {/* text  */}
          <motion.div
            variants={fadeIn('right', 0.3)}
            initial='hidden'
            whileInView={'show'}
            viewport={{ once: true, amount: 0.2 }}
            className='flex-1 flex items-center gap-x-7'
          >
            <div className='w-full font-primary2'>
              <h4 className='text-xl uppercase text-collor4 font-medium mb-2 tracking-wide'>{t('description.contact.text1')}</h4>
              <h2 className='text-[45px] lg:text-[80px] leading-none mb-12 '>{t('description.contact.text2')}</h2>
              <div className='flex justify-around md:justify-start md:gap-x-8 text-collor2 items-center pb-8'>
                <a href='tel:+421910387825' title='Call to +421910387825' className='hover:scale-110 transition-all ease-out'>
                  <CiMobile3 size={35} className='mx-auto' />
                  Phone
                </a>
                <a
                  href='mailto:zilka.tomas421@gmail.com'
                  title='Send mail to zilka.tomas421@gmail.com'
                  target='_blank'
                  rel='noreferrer'
                  className='hover:scale-110 transition-all ease-out'
                >
                  <CiMail size={35} className='mx-auto' />
                  E-Mail
                </a>

                <a
                  href='https://m.me/tomas.zilka.3/'
                  target='_blank'
                  rel='noreferrer'
                  title='Send message via FB messenger'
                  className='hover:scale-110 transition-all ease-out'
                >
                  <PiMessengerLogo size={35} className='mx-auto' />
                  Messenger
                </a>
                <a
                  href='https://www.linkedin.com/feed/?trk=homepage-basic_sign-in-submit'
                  target='_blank'
                  rel='noreferrer'
                  title='Linkedin'
                  className='hover:scale-110 transition-all ease-out'
                >
                  <CiLinkedin size={35} className='mx-auto' />
                  Linkedin
                </a>
              </div>
            </div>
          </motion.div>

          {/* form  */}
          <motion.form
            variants={fadeIn('left', 0.3)}
            initial='hidden'
            whileInView={'show'}
            viewport={{ once: true, amount: 0.3 }}
            onSubmit={handleSubmit}
            action='https://formspree.io/f/mzbnbpjd'
            className='flex-1 border border-collor4 rounded-2xl flex flex-col gap-y-6 p-6 items-start'
          >
            <input
              type='text'
              name='name'
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              className='bg-transparent border-b py-3 outline-none w-full placeholder:text-white/50 focus:border-collor3 transition-all'
              placeholder={t('description.contact.text3')}
            />
            <input
              type='text'
              name='email'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className='bg-transparent border-b py-3 outline-none w-full placeholder:text-white/50 focus:border-collor3 transition-all'
              placeholder={t('description.contact.text4')}
            />
            <textarea
              name='message'
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
              placeholder={t('description.contact.text5')}
              className='bg-transparent border-b py-4 outline-none w-full placeholder:text-white/50 focus:border-collor3 resize-none transition-all  mb-6'
            ></textarea>

            {submitted === true ? (
              <h2 className='h4 font-primary2 text-collor1 bg-collor4 inline p-1'>{t('description.contact.text6')}</h2>
            ) : (
              <button type='submit' className='btn btn-lg mx-auto'>
                {t('description.buttons.sendMsg')}
              </button>
            )}
          </motion.form>
        </div>
      </div>
    </section>
  )
}

export default Contact
