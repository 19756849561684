import React, { useRef } from 'react'
//images
import Image1 from '../assets/desktopPicture.svg'
//icons
import { FaGithub } from 'react-icons/fa'
import { CiMail, CiLinkedin, CiMobile3 } from 'react-icons/ci'
import { BsChevronDown } from 'react-icons/bs'
import { PiMessengerLogo } from 'react-icons/pi'
// type animation
// import { TypeAnimation } from 'react-type-animation'
//motion
import { motion } from 'framer-motion'
import { useAnimationFrame } from 'framer-motion'
//variants
import { fadeIn } from '../features/variants'
//translation
import { useTranslation } from 'react-i18next'
// import Translatore from '../features/translate/Translatore'

const Banner = () => {
  const { t, i18n } = useTranslation()
  const ref = useRef(null)

  useAnimationFrame((t) => {
    // const rotate = Math.sin(t / 10000) * 200
    const y = (1 + Math.sin(t / 1000)) * -6
    const x = (1 + Math.sin(t / 1000)) * -6
    ref.current.style.transform = `translateY(${y}px) translateX(${x}px) `
  })

  return (
    <section id='home' className='min-h-[80vh] lg:min-h-[88vh] flex items-center h-auto py-16'>
      <div className='container mx-auto items-center'>
        {/*  */}
        {/* DESIGN */}
        {/*  */}
        <div className='flex flex-col gap-y-2'>
          <div className='flex flex-col h-auto mx-auto w-fit justify-center '>
            <div className='text-left flex flex-col lg:flex-row'>
              <div className=''>
                {/*  */}
                {/* MAIN TEXT  */}
                {/*  */}
                <div className='flex flex-col justify-start md:items-start z-30 mt-2  font-primary2 '>
                  <h2
                    variants={fadeIn('left', 0.1)}
                    viewport={{ once: true }}
                    initial='hidden'
                    whileInView={'show'}
                    className=' text-[39px] md:text-[59px] md:bg-collor1 px-4  w-fit pl-0 z-40  md:border-s-4 border-collor4 text-collor4 leading-[0.9]  font-primary2 md:pl-2'
                  >
                    {t('description.banner.text1')}
                  </h2>
                  <h2
                    variants={fadeIn('right', 0.3)}
                    viewport={{ once: true }}
                    initial='hidden'
                    whileInView={'show'}
                    className=' text-[39px] md:text-[59px] md:bg-collor1 px-4 pt-4 w-fit mt-[-9px] pl-0  md:border-s-4 border-collor4 leading-[0.9]  z-30 font-primary2 md:pl-2'
                  >
                    {t('description.banner.text2')}
                  </h2>
                  <h2
                    variants={fadeIn('left', 0.5)}
                    viewport={{ once: true }}
                    initial='hidden'
                    whileInView={'show'}
                    className={`relative md:bg-collor1 p-4 my-2 md:my-0 pt-0 pl-0 md:leading-tight  md:border-s-4 border-collor4 z-20 md:text-left font-primary2 md:pl-2 ${
                      i18n.language === 'en'
                        ? 'md:text-[48px] text-[28px] w-[90%] md:w-fit'
                        : 'md:text-[48px] text-[28px] w-[70%]'
                    }`}
                  >
                    {i18n.language === 'sk' ? (
                      <>
                        {/* <span className='absolute left-[31%] top-[-17%] md:left-[44.1%] md:top-[-17%]'>ˇ</span>
                        <span className='absolute left-[43.5%] top-[-17%] md:left-[57.5%] md:top-[-17%]'>ˇ</span> */}
                      </>
                    ) : null}

                    {t('description.banner.text3')}
                  </h2>

                  {/*  */}
                  {/* CONTACT  */}
                  {/*  */}
                  <div
                    variants={fadeIn('right', 0.5)}
                    viewport={{ once: true }}
                    initial='hidden'
                    whileInView={'show'}
                    className='flex bg-collor1 mt-[-10px] flex-row pb-4 md:pr-8 md:border-s-4 border-collor4 gap-x-5 md:gap-x-12 z-50 w-fit '
                  >
                    <motion.div
                      viewport={{ once: true }}
                      variants={fadeIn('left', 0.2)}
                      initial='hidden'
                      whileInView={'show'}
                      wrapper='div'
                    >
                      <a href='tel:+421910387825' title='Call to +421910387825'>
                        <CiMobile3 className='text-collor4 size-[45px] md:size-[65px] hover:text-collor2 transition-all hover:rotate-[360deg] duration-400' />
                      </a>
                    </motion.div>
                    <motion.div
                      viewport={{ once: true }}
                      variants={fadeIn('left', 0.6)}
                      initial='hidden'
                      whileInView={'show'}
                      wrapper='div'
                    >
                      {' '}
                      <a
                        href='mailto:zilka.tomas421@gmail.com'
                        title='Send mail to zilka.tomas421@gmail.com'
                        target='_blank'
                        rel='noreferrer'
                      >
                        <CiMail className='text-collor4 size-[45px] md:size-[65px] hover:text-collor2 transition-all hover:rotate-[360deg] duration-400 ' />
                      </a>
                    </motion.div>
                    <motion.div
                      viewport={{ once: true }}
                      variants={fadeIn('left', 1.0)}
                      initial='hidden'
                      whileInView={'show'}
                      wrapper='div'
                    >
                      {' '}
                      <a
                        href='https://m.me/tomas.zilka.3/'
                        target='_blank'
                        rel='noreferrer'
                        title='Send message via FB messenger'
                      >
                        <PiMessengerLogo className='text-collor4 size-[45px] md:size-[65px] hover:text-collor2 transition-all hover:rotate-[360deg] duration-400 ' />
                      </a>
                    </motion.div>
                    <motion.div
                      viewport={{ once: true }}
                      variants={fadeIn('left', 1.4)}
                      initial='hidden'
                      whileInView={'show'}
                      wrapper='div'
                    >
                      {' '}
                      <a
                        href='https://www.linkedin.com/feed/?trk=homepage-basic_sign-in-submit'
                        target='_blank'
                        rel='noreferrer'
                        title='Linkedin'
                      >
                        <CiLinkedin className='text-collor4 size-[45px] md:size-[65px] hover:text-collor2 transition-all hover:rotate-[360deg] duration-400 ' />
                      </a>
                    </motion.div>
                    <motion.div
                      viewport={{ once: true }}
                      variants={fadeIn('left', 1.8)}
                      initial='hidden'
                      whileInView={'show'}
                      wrapper='div'
                    >
                      <a href='https://github.com/tomix421q' target='_blank' rel='noreferrer' title='Github'>
                        <FaGithub className='text-collor4 size-[45px] md:size-[65px] hover:text-collor2 transition-all hover:rotate-[360deg] duration-400 ' />
                      </a>
                    </motion.div>
                  </div>
                  {/*  */}
                  {/* P */}
                  {/*  */}
                  <div className='md:border-s-4 border-collor4 bg-collor1 md:pl-2'>
                    <motion.p
                      viewport={{ once: true }}
                      variants={fadeIn('left', 2.2)}
                      initial='hidden'
                      whileInView={'show'}
                      wrapper='div'
                      className='text-sm md:text-[20px] text-white/90  font-secondary leading-4 md:leading-6 py-4 px-1 font-extralight w-[95%]  md:text-left'
                    >
                      {t('description.banner.text4')}
                    </motion.p>
                  </div>
                </div>
                {/*  */}
                {/* BUTTONS  */}
                {/*  */}
                <motion.div
                  viewport={{ once: true, amount: 0.1 }}
                  variants={fadeIn('up', 2.2)}
                  initial='hidden'
                  whileInView={'show'}
                  className='flex flex-col md:flex-row gap-y-4 justify-around items-center md:justify-start gap-x-16 md:px-4 md:items-start w-full mt-6 mx-auto p-3'
                >
                  <a
                    href='#about'
                    className='flex items-center btn-lg text-gradient text-md p-2 w-[80%] md:w-[40%] border border-white/80 hover:w-full transition-all justify-center rounded-full logo-gradient'
                  >
                    {t('description.buttons.about')}
                  </a>

                  <a
                    href='#work'
                    className='flex items-center justify-center btn-lg text-gradient text-md p-2 w-[80%] md:w-[40%] border border-white/80 rounded-full hover:w-full transition-all'
                  >
                    {t('description.buttons.portfolio')}
                  </a>
                </motion.div>
              </div>
              {/*  */}
              {/* DESKTOP IMAGE  */}
              {/*  */}
              <motion.div
                viewport={{ once: true }}
                variants={fadeIn('left', 2.3)}
                initial='hidden'
                whileInView={'show'}
                className='justify-center items-center lg:flex hidden mt-[-40px]  relative'
              >
                <img src={Image1} className='w-[500px] md:w-[1500px]' alt='' ref={ref} />
              </motion.div>
            </div>

            {/*  */}
          </div>
          {/*  */}
          {/* SCROLL TO EXPLORE */}
          {/*  */}
          <motion.div
            variants={fadeIn('up', 3)}
            initial='hidden'
            whileInView={'show'}
            wrapper='div'
            className='flex flex-col items-center text-center my-10'
          >
            <a
              href='#about'
              className=' text-white/70 rounded-full p-1 px-6 shadow-lg shadow-black font-primary2 tracking-widest'
            >
              <motion.div animate={{ y: [0, 10, 0] }} transition={{ ease: 'linear', duration: 1.5, repeat: Infinity }}>
                <BsChevronDown size={45} className='text-white/60' />
              </motion.div>
            </a>
          </motion.div>
        </div>
      </div>
    </section>
  )
}

export default Banner
