import React from 'react'
// motion
import { motion } from 'framer-motion'
// variants
import { fadeIn } from '../features/variants'
//translation
import { useTranslation } from 'react-i18next'

const Services = () => {
  const { t } = useTranslation()
  //service data
  const services = [
    {
      name: 'PLC siemens',
      description: t('description.services.text1'),
      link: 'More',
    },
    {
      name: 'ABB | UR robots',
      description: t('description.services.text2'),
      link: 'more',
    },
    {
      name: 'Keyence | Cognex',
      description: t('description.services.text3'),
      link: 'more',
    },
    {
      name: 'Javascript',
      description: t('description.services.text4'),
      link: 'more',
    },
    {
      name: 'C#',
      description: t('description.services.text5'),
      link: 'more',
    },
    {
      name: 'Databases',
      description: t('description.services.text6'),
      link: 'more',
    },
  ]
  return (
    <section id='services' className='section h-auto py-16'>
      <div className='container mx-auto'>
        <div className='flex flex-col lg:flex-row gap-x-12'>
          {/* text image */}
          <motion.div
            variants={fadeIn('right', 0.3)}
            initial='hidden'
            whileInView={'show'}
            viewport={{ once: true, amount: 0.3 }}
            className='flex-1 lg:bg-services bg-left  bg-no-repeat mix-blend-lighten bg-cover  lg:mb-0 '
          >
            <h2 className='h2 text-collor4 mb-6 leading-tight'>{t('description.services.text7')}</h2>
            <h3 className='h3 mx-w-[455px]   md:bg-collor3/60 backdrop-blur-3xl bg-collor1/60 px-1 border-b-2 border-white/20'>
              {t('description.services.text8')}
            </h3>
          </motion.div>
          {/* services  */}
          <motion.div
            variants={fadeIn('left', 0.3)}
            initial='hidden'
            whileInView={'show'}
            viewport={{ once: true, amount: 0.2 }}
            className='flex flex-col'
          >
            {services.map((service, index) => {
              const { name, description } = service
              return (
                <div className='border-b border-white/20 pb-10 flex' key={index}>
                  <div className='max-w-[476px]'>
                    <h4 className='text-[20px] tracking-wider font-primary font-semibold mb-6 text-collor2 pt-4'>{name}</h4>
                    <p className='font-secondary leading-tight'>{description}</p>
                  </div>
                  {/* <div className='flex flex-1 flex-col items-end '>
                    <a href='#'>
                      <BsArrowUpRight className='h-8 w-8 mb-[42px] mt-5 flex justify-center items-center p-1' />
                    </a>
                    <a href='#' className='text-gradient text-sm'>
                      {link}
                    </a>
                  </div> */}
                </div>
              )
            })}
            <a className='btn btn-lg m-4  flex justify-center items-center' href='#work'>
              {t('description.buttons.work')}
            </a>{' '}
          </motion.div>
        </div>
      </div>
    </section>
  )
}

export default Services
