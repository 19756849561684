import { useState, useEffect } from 'react'
import React from 'react'
// translate
import { useTranslation } from 'react-i18next'
import Translatore from '../features/translate/Translatore'
// type animation
import { TypeAnimation } from 'react-type-animation'

const Header = () => {
  const { t, i18n } = useTranslation()
  const [resetAnimation1, setResetAnimation1] = useState(false)

  useEffect(() => {
    // Resetuj animáciu písania textu
    setResetAnimation1(true)
    // Nastav resetAnimation späť na false po krátkej dobe (napr. 100 ms)
    const timeout = setTimeout(() => {
      setResetAnimation1(false)
    }, 100)
    // Zruš timer, ak komponent unmountne
    return () => clearTimeout(timeout)
  }, [i18n.language])

  return (
    <header id='header' className=' py-2 '>
      <div className='container mx-auto '>
        <div className='flex justify-between font-extralight text-sm lg:text-xl'>
          {/*  */}
          {/* Logo  */}
          <div className='h-[45px] md:h-auto  px-1 my-auto '>
            <TypeAnimation
              key={resetAnimation1} // Pridaj key pre reset animácie
              sequence={[1000, t('description.buttons.header1') + ' & ' + t('description.buttons.header2')]}
              speed={10}
              className=' md:leading-tight  leading-5 uppercase  text-gradient2  text-left w-[75%] md:w-full'
              wrapper='div'
            />
          </div>

          {/*  */}
          {/* Translate button */}
          <div className=' font-primary tracking-widest'>
            <Translatore />
          </div>
          {/*  */}
        </div>
      </div>
    </header>
  )
}

export default Header
