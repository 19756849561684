import React from 'react'
// components
import Banner from './components/Banner'
import Header from './components/Header'
import Nav from './components/Nav'
import About from './components/About'
import Services from './components/Services'
import Work from './components/Work'
import Contact from './components/Contact'
import Footer from './components/Footer'

const App = () => {
  return (
    <div className='bg-collor1 bg-no-repeat bg-cover overflow-hidden'>
      <div className='bg-gradient-to-b from-gray-700 via-gray-900 to-black bg-cover bg-no-repeat h-screen w-screen mix-blend-lighten bg-blend-lighten'>
        <Header />
        <Banner />
      </div>
      <Nav />
      <About />
      <Services />
      <Work />
      <Contact />
      <Footer />
      {/* <div className='h-[1000px]'></div> */}
    </div>
  )
}

export default App
